import { Component, inject, OnInit } from '@angular/core';

import { FooterComponent } from '@root/app/layout/footer/footer.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { ROUTE_REDIRECT_URL_PROP } from '@shared/constant';
import { AppRouteService } from '@shared/services/app-route.service';
import { AuthStoreService } from '@core/services/auth-service/auth-store.service';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [
    FooterComponent,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    MatCardModule,
    RouterLinkWithHref,
  ],
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  private readonly router = inject(Router);
  protected readonly authStoreService = inject(AuthStoreService);
  private readonly route = inject(ActivatedRoute);
  private redirectUrl!: string;

  ngOnInit() {
    this.authStoreService.stopTrackRefreshToken();
    this._handleRedirect();
  }

  protected navToRedirectPage() {
    window.location.href = this.redirectUrl;
  }

  protected navToHome() {
    this.router.navigateByUrl(AppRouteService.homePath());
  }

  private _handleRedirect() {
    this.redirectUrl = this.route.snapshot.queryParams[ROUTE_REDIRECT_URL_PROP];
    if (!this.authStoreService.$errorMessage()) {
      if (this.redirectUrl) {
        this.navToRedirectPage();
      } else {
        this.navToHome();
      }
    }
  }
}
