<mat-card
  appearance="outlined"
  class="bg-primary-lighter"
>
  <mat-card-content class="al-wrapper">
    <span class="ad-label">
      {{ authStoreService.$errorMessage() ?? '' | translate }}
    </span>
    <mat-icon
      class="aw-icon"
      color="warn"
    >
      error_outline
    </mat-icon>
    <button
      mat-raised-button
      color="primary"
      (click)="navToRedirectPage()"
    >
      <span>{{ 'shared.retry' | translate }}</span>
    </button>
  </mat-card-content>
</mat-card>
